<template>
	<div>
		<iframe v-if="$store.state.showIframe.iframeVR" class="iframeBox" :src="$store.state.showIframe.VRSrc"
			frameborder="0"></iframe>
		<div v-if="$store.state.showIframe.iframeVR"
			@click="$store.dispatch(`showIframe/setItemFlase`, `iframeVR`)" class="close-btn2">
			<img src="../../assets/img/header/closed_02.png" />
		</div>
		<!-- <el-button class="iconInIframe" icon="el-icon-circle-close " v-if="$store.state.showIframe.iframeVR"
			@click="$store.dispatch(`showIframe/setItemFlase`, `iframeVR`)"></el-button> -->
	</div>
</template>
<script>
	export default {
		name: "iframeVR",
		props: {

		},
		data() {
			return {
				src: "",
			};
		},
		methods: {
			resetCity() {
				this.$emit("resetCity");
			},
		},
		mounted() {},
	};
</script>

<style scoped lang="scss">
	/deep/ .close-btn2 {
		left: 75.8% !important;
		top: 90px !important;
	}
</style>
