<template>
	<div class="city_introduction">
		<!-- 控制窗口显示的箭头按钮 -->
		<div class="arrow_btn">
			<img @click="isShowHigher = false" v-if="isShowHigher" src="@/assets/img/icon_close.png"/>
			<img @click="isShowHigher = true" v-else src="@/assets/img/icon_open.png" />
		</div>
		<div :class="isShowHigher ? 'large_center_bg large_center_higher_bg showHigherWarp' : 'large_center_bg hideHigherWarp'">
			<!-- 简介标题 -->
			<div class="title_box fl_c al_c ju_c">
				{{$store.state.region.item.town_name + $store.state.introduct.title}}
			</div>
			<!-- 简介内容 滚动区域 -->
			<template v-if="intro_content != ''">
				<vue-seamless-scroll
					v-if="!isShowHigher"
					:data="listData"
					:class-option="optionSetting"
					class="warp"
				>
					<div v-html="intro_content" class="rich-text"></div>
				</vue-seamless-scroll>
				<!-- 拉高，则禁止滚动 -->
				<div v-else class="warp higher_warp">
					<div v-html="intro_content" class="rich-text"></div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
import { getCityList } from '@/utils/api/region.js'
import { mapState } from 'vuex';
export default {
	name: "CityIntroduction",
	components: {
		vueSeamlessScroll
	},
	data() {
		return {
			isShowHigher: false, // 是否拉高简介部分
			listData: [1, 2, 3, 4, 5, 6, 7],
			intro_content: ''
		};
	},
	mounted() {
		this.getCityList();
	},
	computed: {
		...mapState(['region']),
		optionSetting() {
			return {
				limitMoveNum: 0,
				direction: 1,
				step: 0.2,
			}
		}
	},
	methods: {
		async getCityList() {
			let res = await getCityList({
				town_id: this.region.item.town_code
			});
			if(res.data.areaList[0].childList[0].profile != '') {
				this.intro_content = res.data.areaList[0].childList[0].profile;
			}
		}
	}
};
</script>

<style scoped lang="scss">
	.city_introduction {
		width: 100%;
		.arrow_btn {
			text-align: center;
			margin-bottom: 10px;
			img {
				width: 35px;
				height: 35px;
				pointer-events: auto;
				cursor: pointer;
			}
		}
		.large_center_bg {
			width: 100%;
			height: 220px;
			background-image: url(./../../assets/img/pop_down.png);
			background-repeat: no-repeat;
			background-size: 100% 100%;
			display: block;
			margin: 0 auto;
			overflow: hidden;
			box-sizing: border-box;
			pointer-events: auto;
			margin-bottom: 15px;
			.title_box {
				flex-direction: row;
				justify-content: flex-start;
				padding: 18px 20px;
				height: 32px;
				font-size: 18px;
				background-image: linear-gradient(270deg, rgba(0,230,224,0.00) 0%, rgba(0,230,224,0.35) 98%);
				color: #fff;
				margin: 32px 48px 0;
				border-left: 5px solid #00e6e0;
			}
			.warp {
				width: 854px;
				height: 110px;
				margin: 20px auto 30px;
				display: block;
				overflow: hidden;
				font-size: 14px;
				font-weight: 400;
				line-height: 30px;
				color: #fff;
				font-size: 14px;
				.rich-text {
					/deep/ p {
						text-indent: 2em !important;
					}
				}
			}
			
			.higher_warp {
				height: 490px;
				font-size: 16px;
				padding-top: 6px;
			}
		}
		.large_center_higher_bg {
			height: 610px;
			background: linear-gradient(130deg, rgba(36,134,185, 0.32) 0%, rgba(32, 45, 70, 0.29) 100%);
			// background: linear-gradient(0deg, rgba(20,26,36,0.8) 0%, rgba(32,45,70,0.29) 100%);
		}
	}
	
	.showHigherWarp {
		animation: toHigher 0.5s ease;
		animation-fill-mode: forwards;
	}
	
	.hideHigherWarp {
		animation: toLower 0.5s;
		animation-fill-mode: forwards;
	}
	
	// 简介拉高显示
	@keyframes toHigher{
		0%{
			height: 220px;
		}
		100% {
			height: 610px;
		}
	}
	// 简介还原
	@keyframes toLower {
		0%{
			height: 610px;
		}
		100% {
			height: 220px;
		}
	}
</style>
