export function findCenterPoint(points) {
	if(points.length > 0) {
		const validPoints = points.filter(point => point.latitude !== null && point.longitude !== null);

		let centerPoint = validPoints.reduce((prev, curr) => calculateTotalDistance(prev, validPoints) < calculateTotalDistance(curr, validPoints) ? prev : curr);
	
		return {latitude: centerPoint.latitude, longitude: centerPoint.longitude};
	} else {
		return {latitude: 25.075713, longitude: 117.017227};
	}
}

function calculateDistance(point1, point2) {
	return Math.sqrt(Math.pow(point1.latitude - point2.latitude, 2) + Math.pow(point1.longitude - point2.longitude, 2));
}

function calculateTotalDistance(point, points) {
	let totalDistance = 0;
	points.forEach(otherPoint => {
		totalDistance += calculateDistance(point, otherPoint);
	});
	return totalDistance;
}