<template>
	<!-- 1.创建地图容器元素 -->
	<div>
		<div class="celestial_map" id="mapDiv">
			<div id="mapDiv"></div>
		</div>
		<div v-if="ifShowPop" class="pop_style">
			<img src="../../assets/img/btn-closed.png" class="cancel_sty" @click="closePop" alt="" />
			<div class="img_box">
				<el-carousel trigger="click" height="700px" v-if="reportImg.length > 0" arrow="always">
					<el-carousel-item v-for="(item, index) in reportImg" :key="index">
						<img :src="item" class="img_item" alt="" preview="1" />
					</el-carousel-item>
				</el-carousel>
				<div style="font-size: 35px; text-align: center; line-height: 600px" v-else>
					暂无农检报告图片
				</div>
			</div>
		</div>
		<iframeImg />
		<iframeVideo />
		<iframeVR />
		<iframeTable />
	</div>
</template>

<script>
	import iframeImg from "@/components/iframe/iframeImg";
	import iframeVideo from "@/components/iframe/iframeVideo";
	import iframeVR from "@/components/iframe/iframeVR";
	import iframeTable from "@/components/iframe/iframeTable";

	import {
		findCenterPoint
	} from '@/utils/tools.js';

	import {
		getAgricultureCoordinate,
		getCulturalCoordinate,
		getTourismCoordinate,
		getAttractInvestmentCoordinate,
		getPartyCoordinate,
		getTalentsCoordinate,
		getScreenCoordinate,
		getFarmCoordinate
	} from "@/utils/api/index.js";
	import {
		time
	} from "echarts";

	import {
		mapState
	} from 'vuex'; // 从vuex中导入mapState

	export default {
		name: "CelestialMap",
		components: {
			iframeImg,
			iframeVideo,
			iframeVR,
			iframeTable
		},
		data() {
			return {
				markerObject: [], // 实例化的marker对象数组
				clustererObject: [], // 聚合点数组
				imageURL: "https://t0.tianditu.gov.cn/img_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=f5c252c1d8ffa5a01d3c3e74c15f4291",
				centerList: [], //中心点,新罗区的经纬度
				markerArrs: [], // 所有资产点位，非marker对象实例
				videoMarkers: [], // 所有摄像头点位
				line: null,
				ifShowPop: false,
				reportImg: [],
				buttonUrl: `../../assets/img/list2.png`,
				monitorVideo: [{
						name: '山塘村爱国主义教育基地'
					},
					{
						name: '山塘兵工厂'
					},
					{
						name: '睡美人农庄'
					},
					{
						name: '梦之春果园'
					},
					{
						name: '静庄生态农庄'
					},
					{
						name: '雪云居康养中心'
					},
					{
						name: '芳雲园山庄'
					},
					{
						name: '江山财神庙'
					},
					{
						name: '龙岩起义旧址'
					},
					{
						name: '石佛公'
					},
				]
			};
		},
		computed: {
			...mapState(['region']), // 这里的user 指代的是模块名 /  如果是没有单独分开模块，则直接放state里的变量名
		},
		async mounted() {
			await this.homepageMap();
			await this.initMap();
		},
		methods: {
			closePop() {
				this.ifShowPop = false;
				this.homepageMap();
			},
			async homepageMap() {
				if (this.$route.path == `/smartAgriculture`) {
					// pointType 的值可以是 'all', 'farm', 'village'
					console.log(this.$store.state.map.pointType,'----this.$store.state.map.pointType;')
					this.markerArrs = [];
					this.markerObject = [];
					let pointType = this.$store.state.map.pointType;
					// 大屏首页点位
					let screenCoordinate = await getScreenCoordinate({
						town: this.region.item.town_code,
						village: this.region.item.village_id
					});
					// 供销农场点位
					let FarmCoordinate = await getFarmCoordinate({
						town: this.region.item.town_code,
						village: this.region.item.village_id
					});
					// 根据 pointType 的值决定 markerArrs 应该包含哪些数据
					if (pointType === 'all') {
					    this.markerArrs = [...FarmCoordinate.data, ...screenCoordinate.data];
					} else if (pointType === 'farm') {
					    this.markerArrs = FarmCoordinate.data;
					} else if (pointType === 'village') {
					    this.markerArrs = screenCoordinate.data;
					}
					//this.markerArrs = [...FarmCoordinate.data,...screenCoordinate.data];
				}
					
				if (this.$route.path == `/invest`) {
					let AttractInvestmentCoordinate = await getAttractInvestmentCoordinate({
						town: this.region.item.town_code,
						village: this.region.item.village_id
					})
					this.markerArrs = AttractInvestmentCoordinate.data;
				}
					
				if (this.$route.path == `/farming`) {
					let farming = await getAgricultureCoordinate({
						town: this.region.item.town_code,
						village: this.region.item.village_id
					})
					this.markerArrs = farming.data;
				}
				
				if (this.$route.path == `/talents`) {
					let TalentsCoordinate = await getTalentsCoordinate({
						town: this.region.item.town_code,
						village: this.region.item.village_id
					})
					this.markerArrs = TalentsCoordinate.data;
				}
					
				if (this.$route.path == `/partyBuilding`) {
					let PartyCoordinate = await getPartyCoordinate({
						town: this.region.item.town_code,
						village: this.region.item.village_id
					})
					this.markerArrs = PartyCoordinate.data;
				}
					
				if (this.$route.path == `/culturalTourism`) {
					let TourismCoordinate = await getTourismCoordinate({
						town: this.region.item.town_code,
						village: this.region.item.village_id
					});
					let cultural = await getCulturalCoordinate({
						town: this.region.item.town_code,
						village: this.region.item.village_id
					});
					this.markerArrs = [...TourismCoordinate.data, ...cultural.data];
				}
					
				this.$store.dispatch(`map/setArr`, this.markerArrs);
			},
			async initMap() {
				const imageURL = "https://t1.tianditu.gov.cn/img_w/wmts?" +
					"SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles" +
					"&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}" +
					"&tk=f5c252c1d8ffa5a01d3c3e74c15f4291";
				
				const imageURLT = "https://t1.tianditu.gov.cn/cia_w/wmts?" +
					"SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cia&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles" +
					"&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}" +
					"&tk=f5c252c1d8ffa5a01d3c3e74c15f4291";
				
				let T = window.T;
				
				const lay = new T.TileLayer(imageURL, {
					minZoom: 1,
					maxZoom: 18
				});
				// 影像注记图层
				const lay2 = new T.TileLayer(imageURLT, {
					minZoom: 1,
					maxZoom: 18
				});
				const config = {
					layers: [lay, lay2]
				};
				
				// 获取中心点位
				const centerPoint = findCenterPoint(this.markerArrs);
				
				this.map = new T.Map("mapDiv", config); // 地图实例
				this.map.centerAndZoom(
					new T.LngLat(centerPoint.longitude, centerPoint.latitude),
					this.region.item.town_code !== '' ? 14 : 9
				); //设置中心点
				this.map.enableScrollWheelZoom(); //允许鼠标双击放大地图
				this.map.setMaxZoom(18);
				await this.getMarkers();
				// 加载点聚合
				await this.addClusterObject()
			},
			getMarkers() {
				let T = window.T;
				// marker 图标
				let icon = new T.Icon({
					iconUrl: require('@/assets/img/icon_address2.png'), //请求图标图片的URL
					iconSize: new T.Point(49, 40), //图标可视区域的大小。
					iconAnchor: new T.Point(49, 40) //图标的定位锚点
				})
				let videoIcon = new T.Icon({
					iconUrl: require('@/assets/img/icon_video.png'), //请求图标图片的URL
					iconSize: new T.Point(79, 70), //图标可视区域的大小。
					iconAnchor: new T.Point(79, 70) //图标的定位锚点
				})
				this.markerArrs.forEach((item) => {
					let videoName = '';
					if (this.$route.path == `/culturalTourism` || this.$route.path == `/farming`) {
						this.monitorVideo.forEach((monitor_video_item) => {
							if (monitor_video_item.name == item.name) {
								// console.log(item.name, 333)
								videoName = monitor_video_item.name;
								switch (videoName) {
									case '山塘兵工厂':
										Object.assign(item, {
											monitor_video: "ezopen://open.ys7.com/AC6127845/1.hd.live"
										})
										break;
									case '山塘村爱国主义教育基地':
										Object.assign(item, {
											monitor_video: "ezopen://open.ys7.com/AC6127826/1.hd.live"
										})
										break;
									case '梦之春果园':
										Object.assign(item, {
											monitor_video: "ezopen://open.ys7.com/AC6127818/1.hd.live"
										})
										break;
									case '石佛公':
										Object.assign(item, {
											monitor_video: "ezopen://open.ys7.com/AC6127833/1.hd.live"
										})
										break;
									case '雪云居康养中心':
										Object.assign(item, {
											monitor_video: "ezopen://open.ys7.com/AC6127856/1.hd.live"
										})
										break;
									case '芳雲园山庄':
										Object.assign(item, {
											monitor_video: "ezopen://open.ys7.com/L24713776/1.hd.live"
										})
										break;
								}
							}
						});
					}
					
					let marker = new T.Marker(new T.LngLat(item.longitude, item.latitude), {
						icon: icon
					}); //创建标注对象
					
					let videoMarker = null;
					if(videoName == item.name) {
						videoMarker = new T.Marker(new T.LngLat(item.longitude, item.latitude), {
							icon: videoIcon
						}); //创建标注对象
						// console.log(videoMarker,'---videoMarker')
						// console.log(item,'---item')
						this.videoMarkers.push(videoMarker);
						//向地图上添加标注
						this.map.addOverLay(videoMarker);
						//点击事件出弹框
						this.addwindowclick(videoMarker, item); 
					}
					
					// 点击事件出弹框
					this.addwindowclick(marker, item);
					// 添加聚合对象
					this.markerObject.push(marker);
				
					if (
						(this.$route.path != "/partyBuilding") &&
						(this.$route.path != "/talents") && 
						(item?.addArr || item?.list)
					) {
						let polylineData = item.addArr || item.list;
						this.addPolyline(polylineData);
					}
				});
			},
			// 添加电子围栏
			addPolyline(addArr) {
				let T = window.T;
				let points = [];
				if (addArr) {
					// 检查addArr是否为字符串
					if (typeof addArr === 'string') {
						JSON.parse(addArr).map((val) => {
							points.push(new T.LngLat(val[0], val[1]));
						});
					} else {
						// 如果addArr不是字符串，则直接处理
						addArr.map((val) => {
							points.push(new T.LngLat(val.lng, val.lat));
						});
					}
				}
				let color = [
						`/invest`,
						`/farming`,
						`/talents`,
						`/partyBuilding`,
						`/culturalTourism`,
					].includes(this.$route.path) ?
					`#00E6E0` :
					`#00E6E0`;
				this.line = new T.Polyline(points, {
					color,
					weight: 2,
					opacity: 1
				}); //创建线对象
				this.map.addOverLay(this.line); //向地图上添加线
			},
			// 添加聚合点
			addClusterObject() {
				let T = window.T;
				// 加载点聚
				this.clustererObject = new T.MarkerClusterer(this.map, {
					markers: this.markerObject
				})
				// 设置聚合图标的最大层级，也就是说当到达18级时，所有聚合的点会全部
				this.clustererObject.setMaxZoom(18)
				this.clustererObject.setStyles([{
					url: require('@/assets/img/icon_address2.png'), // 聚合的图标
					size: [49, 40], //聚合的图标大小        
					offset: new T.Point(0, 0), //显示图片的偏移量        
					textColor: '#fff', //显示数字的颜色        
					textSize: 10, //显示文字的大小       
					range: [0, 10000],
				}]);
			},
			// 隐藏除当前点击的坐标点外，所有的点位
			hideOtherMarkers(currentMarker,item) {
				// 移除所有覆盖物（如marker、polyline等）
				this.map.clearOverLays();
				// 移除所有聚合点
				this.clustererObject.clearMarkers(this.markerObject);
				
				// 添加当前所点击的marker
				this.map.addOverLay(currentMarker);
				// 添加当前所点击marker的电子围栏
				this.addPolyline(item.addArr);
			},
			// 显示点位
			showAllMarkers() {
				let T = window.T;
				this.markerArrs.forEach((item) => {
					if (
						(this.$route.path != "/partyBuilding") &
						(this.$route.path != "/talents")
					) {
						this.addPolyline(item.addArr);
					}
				});
				// 加载点聚合
				this.addClusterObject()
				// 加载摄像头坐标
				this.videoMarkers.forEach(item => {
					this.map.addOverLay(item);
				});
				
				//点击事件出弹框
				//this.addwindowclick(videoMarker, item); 
			},
			addwindowclick(marker, item) {
				let T = window.T;
				marker.addEventListener("click", () => {
					this.map.panTo(new T.LngLat(item.longitude, item.latitude));
					if (this.$route.path == `/culturalTourism`) {
						this.$store.dispatch(`travel/setItem`, item);
					} else if (this.$route.path == `/talents`) {
						this.$store.dispatch(`talents/setItem`, item);
					} else if (this.$route.path == `/partyBuilding`) {
						this.$store.dispatch(`partPeople/setItem`, item);
					} else if (this.$route.path == `/farming`) {
						this.$store.dispatch(`farming/setItem`, item);
					}
					
					// 隐藏其他点位
					this.hideOtherMarkers(marker,item);
					
					//console.log(item,'----当前所点击的点位')

					let infoWin = new T.InfoWindow();
					// 用亩为单位的type类型值
					let typeArr = ['2','3','8','9','12','13','20','22','23','24'];
					let sContent = `
						<div class = 'opic' style="margin: 0px;">
							<div class="opic_lt">
								<div class="icon_box">
									<div class="img"></div>
								</div>
								<div class="line"></div>
							</div>
							<div class="opic_rt">
								<div class="title" style="font-size: 16px;font-weight: 500;color: #fff;padding-left: 24px;">
									<span>${item.name}</span>
								</div>
								<div class="content_box" style="font-size: 14px;font-weight: 500;color: #fff">
									<span class="lt_icon"></span>
									<span class="rt_icon"></span>
									<div class="details">
										${
											[`/smartAgriculture`, `/invest`, `/farming`, `/culturalTourism`].includes(this.$route.path)
											? 
											`
											<div>联系人：${item.linkman ? item.linkman : '-'}</div>
											<div>联系电话：${item.tel ? item.tel : '-'}</div>
											<div>位置：${item.address ? item.address : '-'}</div>
											<div>类型：${item.produName ? item.produName : item.typeName ? item.typeName : '-'}</div>
											${item.area ? `<div>面积：${item.area}${typeArr.includes(item.type) ? '(亩)' : '(平方米)'}</div>` : ''}
											<div>详情：${item.details ? item.details : '-'}</div>
											`
											: this.$route.path == `/talents`
											? item.now_work_address
											: this.$route.path == `/partyBuilding`
											? item.in_department : '根据数据保密协议本数据暂时隐藏'
										}
									</div>
									${
										[`/talents`].includes(this.$route.path)
										? ``
										: [`/partyBuilding`].includes(this.$route.path)
										? ` <div class="btn_group" style= " display: flex;justify-content: flex-end;" >
												<div class="checkReport btn assets">查看</div>
											</div>`
										: ` <div class="btn_group" style= " display: flex;justify-content: space-between;" >
												<div class="checkReport btn video">视频</div>
												<div class="checkReport btn vr">VR</div>
												<div class="checkReport btn image">图片</div>
											</div>`
									}
								</div>
							</div>
						</div>`;
					marker.openInfoWindow(infoWin.setContent(sContent));

					let flag = [`/talents`].includes(this.$route.path);
					let flag2 = [`/partyBuilding`].includes(this.$route.path);

					if (!flag && !flag2) {
						// Video展示
						let length1 = document.getElementsByClassName('video').length - 1
						let clickVideo = document.getElementsByClassName('video')[length1]
						clickVideo.addEventListener("click", () => {
							this.$store.dispatch(`showIframe/setItemTrue`, {
								name: `iframeVideo`,
								src: item.video ?
									item.video :
									(item.shop_video ? item.shop_video : this.region.vrUrl),
							});
						})

						// VR展示
						let length2 = document.getElementsByClassName('vr').length - 1
						let clickVR = document.getElementsByClassName('vr')[length2]
						clickVR.addEventListener("click", () => {
							this.$store.dispatch(`showIframe/setItemTrue`, {
								name: `iframeVR`,
								src: item.vRaddress ?
									item.vRaddress :
									this.region.vrUrl,
							});
						})

						// 图片展示
						let length3 = document.getElementsByClassName('image').length - 1
						let clickImage = document.getElementsByClassName('image')[length3]
						clickImage.addEventListener("click", () => {
							if(item?.agriculturalInspectionReportPics) {
								this.ifShowPop = true;
								this.reportImg = item.agriculturalInspectionReportPics;
							}else if (item.image.length != 2 && item.image.length != '') {
								this.$store.dispatch(`showIframe/setItemTrue`, {
									name: `iframeImg`,
									src: item.image ? item.image : ``,
								});
							} else {
								this.$message({
									showClose: true,
									message: '当前坐标点位没有图片可以展示',
									type: 'warning'
								});
							}
						})
					}
					if(flag2) {
						// Assets 帮扶对象查看
						let length4 = document.getElementsByClassName('assets').length - 1
						let clickAssets = document.getElementsByClassName('assets')[length4]
						clickAssets.addEventListener("click", () => {
							console.log(item,'---当前点击')
							if(item?.partyProdcutList && item.partyProdcutList[0] !== null) {
								this.$store.dispatch(`showIframe/setTable`, {
									name: `iframeAssets`,
									list: item.partyProdcutList
								});
							} else {
								this.$message({
									showClose: true,
									message: '当前暂时没有可查看的数据',
									type: 'warning'
								});
							}
						})
					}
					// 监听信息窗关闭事件
					infoWin.addEventListener("clickclose", () => {
						// 显示所有点位
					    this.showAllMarkers()
					});
				});
			},
		},
		watch: {
			'$store.state.map.pointType': async function(newVal, oldVal) {
				// 移除所有覆盖类
				this.map.clearOverLays();
				// 移除所有聚合点
				this.clustererObject.clearMarkers(this.markerObject);
				// 回调接口，加载点位数据
				await this.homepageMap();
				let T = window.T;
				const centerPoint = findCenterPoint(this.markerArrs);
				this.map.centerAndZoom(
					new T.LngLat(centerPoint.longitude, centerPoint.latitude),
					this.region.item.town_code !== '' ? 14 : 9
				); //设置中心点
				// 遍历显示marker
				await this.getMarkers();
				// 加载聚合点
				await this.addClusterObject()
			},
			"$store.state.map.longitude": function(newValue, oldValue) {
				this.$store.dispatch(`showIframe/setItemFlase`, `iframeVideo`);
				this.$store.dispatch(`showIframe/setItemFlase`, `iframeImg`);
				this.$store.dispatch(`showIframe/setItemFlase`, `iframeVR`);
				let T = window.T;
				
				this.map.centerAndZoom(
					new T.LngLat(
						this.$store.state.map.longitude,
						this.$store.state.map.latitude
					),
					15
				); //设置中心点
				
				let pLngLat = new T.LngLat(
					this.$store.state.map.longitude,
					this.$store.state.map.latitude
				);
				
				// marker 图标
				let icon = new T.Icon({
					iconUrl: require('@/assets/img/icon_address2.png'), //请求图标图片的URL
					iconSize: new T.Point(49, 40), //图标可视区域的大小。
					iconAnchor: new T.Point(49, 40) //图标的定位锚点
				})
				let marker = new T.Marker(pLngLat, {
					icon: icon
				}); //创建标注对象
				
				// 隐藏其他点位
				this.hideOtherMarkers(marker,this.$store.state.map.item);

				// 用亩为单位的type类型值
				let typeArr = ['2','3','8','9','12','13','20','22','23','24'];
				let sContent = `
					<div style="margin: 0px" class="opic">
						<div class="opic_lt">
							<div class="icon_box">
								<div class="img"></div>
							</div>
							<div class="line"></div>
						</div>
						<div class="opic_rt">
							<div class="title" style="font-size: 16px;font-weight: 500;color: #fff;padding-left: 24px;">
								<span>${this.$store.state.map.item.name}</span>
							</div>
							<div class="content_box" style="font-size: 14px;font-weight: 500;color: #fff">
								<span class="lt_icon"></span>
								<span class="rt_icon"></span>
								<div class="details">
									${
										[`/smartAgriculture`, `/invest`, `/farming`, `/culturalTourism`].includes(this.$route.path)
										? 
										`
										<div>联系人：${this.$store.state.map.item.linkman ? this.$store.state.map.item.linkman : '-'}</div>
										<div>联系电话：${this.$store.state.map.item.tel ? this.$store.state.map.item.tel : '-'}</div>
										<div>位置：${this.$store.state.map.item.address}</div>
										<div>类型：${this.$store.state.map.item.produName ? this.$store.state.map.item.produName : this.$store.state.map.item.typeName ? this.$store.state.map.item.typeName : '-'}</div>
										${this.$store.state.map.item.area ? `<div>面积：${this.$store.state.map.item.area}${typeArr.includes(this.$store.state.map.item.type) ? '(亩)' : '(平方米)'}</div>` : ''}
										<div>详情：${this.$store.state.map.item.details ? this.$store.state.map.item.details : ''}</div>
										`
										: this.$route.path == `/talents`
										? this.$store.state.map.item.now_work_address
										: this.$route.path == `/partyBuilding`
										? this.$store.state.map.item.in_department : '根据数据保密协议本数据暂时隐藏'
									}
								</div>
								${
									[`/talents`].includes(this.$route.path)
									? ``
									: [`/partyBuilding`].includes(this.$route.path)
									? ` <div class="btn_group" style= " display: flex;justify-content: flex-end;" >
											<div class="checkReport btn assets">查看</div>
										</div>`
									: ` <div class="btn_group" style= " display: flex;justify-content: space-between;" >
											<div class="checkReport btn video">视频</div>
											<div class="checkReport btn vr">VR</div>
											<div class="checkReport btn image">图片</div>
										</div>`
								}
							</div>
						</div>
					</div>`;
				var infoWindow = new T.InfoWindow(sContent);
				
				this.map.openInfoWindow(infoWindow, pLngLat);

				let flag = [`/talents`].includes(this.$route.path);
				let flag2 = [`/partyBuilding`].includes(this.$route.path);
				if (!flag && !flag2) {
					// Video展示
					let length1 = document.getElementsByClassName('video').length - 1
					let clickVideo = document.getElementsByClassName('video')[length1]
					clickVideo.addEventListener("click", () => {
						this.$store.dispatch(`showIframe/setItemTrue`, {
							name: `iframeVideo`,
							src: this.$store.state.map.item.video ?
								this.$store.state.map.item.video :
								this.region.vrUrl,
						});
					})

					// VR展示
					let length2 = document.getElementsByClassName('vr').length - 1
					let clickVR = document.getElementsByClassName('vr')[length2]
					clickVR.addEventListener("click", () => {
						this.$store.dispatch(`showIframe/setItemTrue`, {
							name: `iframeVR`,
							src: this.$store.state.map.item.vRaddress ?
								this.$store.state.map.item.vRaddress :
			 				this.region.vrUrl,
						});
					})

					// 图片展示
					let length3 = document.getElementsByClassName('image').length - 1
					let clickImage = document.getElementsByClassName('image')[length3]
					clickImage.addEventListener("click", () => {
						console.log(this.$store.state.map.item)
						this.$store.dispatch(`showIframe/setItemTrue`, {
							name: `iframeImg`,
							src: this.$store.state.map.item.image ? this.$store.state.map.item.image :
								``,
						});
					})
				}
				if(flag2) {
					// Assets 帮扶对象查看
					let length4 = document.getElementsByClassName('assets').length - 1
					let clickAssets = document.getElementsByClassName('assets')[length4]
					clickAssets.addEventListener("click", () => {
						if(this.$store.state.map.item?.partyProdcutList && this.$store.state.map.item.partyProdcutList[0] !== null) {
							this.$store.dispatch(`showIframe/setTable`, {
								name: `iframeAssets`,
								list: this.$store.state.map.item.partyProdcutList
							});
						} else {
							this.$message({
								showClose: true,
								message: '当前暂时没有可查看的数据',
								type: 'warning'
							});
						}
					})
				}
				// 监听信息窗关闭事件
				infoWindow.addEventListener("clickclose", () => {
					// 显示所有点位
				    this.showAllMarkers()
				});
			},
		},
	};
</script>

<style scoped lang="scss">
	.celestial_map {
		width: 100%;
		height: 100%;
		position: fixed;
		z-index: 0;
	}

	.pop_style {
		position: fixed;
		z-index: 9999999;
		display: flex;
		flex-direction: column;
		left: 50%;
		top: 44%;
		transform: translate(-50%, -50%);
		width: 51%;
	}

	.cancel_sty {
		//margin: 10px 0px 10px 950px;
		width: 45px;
		height: 45px;
		position: absolute;
		right: -55px;
		top: 10px;
		cursor: pointer;
		pointer-events: auto;
	}

	.img_box {
		background: rgba(255, 255, 255, 0.8);
		position: relative;
	}

	.img_item {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	/deep/ .el-carousel__arrow {
		background-color: rgba(0, 0, 0, 0.6);
	}

	.get {
		display: flex;
		justify-content: center;
		align-content: center;
	}

	#mapDiv {
		/* filter: brightness(1.1); */
	}

	.opic {
		background-color: rgba(218, 21, 21, 0.3)
	}

	/deep/ .tdt-infowindow {
		left: -73px !important;
	}

	/deep/ .tdt-infowindow-content-wrapper,
	.tdt-infowindow-tip {
		background: transparent;
		box-shadow: none;
		width: auto !important;

		.tdt-infowindow-content {
			width: auto !important;

			.opic {
				display: flex;

				.opic_lt {
					display: flex;
					flex-direction: column;
					align-items: center;

					.icon_box {
						//flex: 1;
						width: 56px;
						height: 56px;
						background-repeat: no-repeat;
						background-size: 100% 100%;
						background-image: url(../../assets/img/pop_02.png);
						position: relative;

						.img {
							width: 40px;
							height: 40px;
							background-repeat: no-repeat;
							background-size: 100% 100%;
							background-image: url(../../assets/img/icon_address.png);
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
						}
					}

					.line {
						height: 100%;
						width: 7%;
						box-sizing: border-box;
						flex: 1;
						background-image: linear-gradient(180deg, #89b3fe 0%, rgba(255, 255, 255, 0) 100%);
						border-radius: 69px 69px 0 0;
					}
				}

				.opic_rt {
					flex: 1;

					.title {
						width: 400px;
						height: 56px;
						background-repeat: no-repeat;
						background-size: 100% 100%;
						background-image: url(../../assets/img/pop_01.png);
						line-height: 56px;
						overflow: hidden;
						text-overflow: ellipsis;
						letter-spacing: 1px;
					}

					.content_box {
						background-image: linear-gradient(0deg, rgba(20, 26, 38, 0.03) 0%, rgba(10, 33, 90, 0.59) 50%);
						height: auto;
						width: 100%;
						padding: 24px 20px 45px;
						position: relative;

						.details {
							text-align: left;
							line-height: 25px;
							margin-bottom: 16px;
							letter-spacing: 1px;
						}

						.btn_group {
							.btn {
								background-repeat: no-repeat;
								background-size: 100% 100%;
								background-image: url(../../assets/img/button_pop_01.png);
								width: 98px;
								height: 32px;
								line-height: 32px;
								text-align: center;
								letter-spacing: 1px;
								cursor: pointer;
							}
						}

						.lt_icon {
							position: absolute;
							left: 0;
							top: 0;
							background-repeat: no-repeat;
							background-size: 100% 100%;
							background-image: url(../../assets/img/header/bevel_01.png);
							width: 10px;
							height: 10px;
						}

						.rt_icon::after {
							content: '';
							background-repeat: no-repeat;
							background-size: 100% 100%;
							background-image: url(../../assets/img/header/bevel_01.png);
							width: 10px;
							height: 10px;
							transform: scaleX(-1);
							position: absolute;
							right: 0;
							top: 0;
						}
					}
				}
			}
		}
	}

	/deep/ .tdt-infowindow-tip-container {
		.tdt-infowindow-tip {
			background: transparent;
			box-shadow: none;
		}
	}

	/deep/ .tdt-container a.tdt-infowindow-close-button {
		font-size: 30px !important;
		padding: 15px 15px 0 0 !important;
		color: #fff;
		top: 18px !important;
		right: 40px;
		font-weight: 400 !important;
	}
</style>
