<template>
	<div>
		<div v-if="$store.state.showIframe.iframeAssets" class="iframeBox">
			<el-table 
				:data="$store.state.showIframe.AssetsList" 
				stripe lazy height="90%"
				style="width: 80%; margin: 30px auto;"
				:header-cell-style="{ backgroundColor: 'transparent', color: '#fff' }"
				:header-row-style="{height: '95px'}"
				:row-style="{height: '60px', color: '#fff',background: 'transparent'}"
			>
				<el-table-column prop="product_name" label="帮扶对象" align="center" empty-text="">
					<!-- <template slot-scope="scope">{{scope.row.product_name ? scope.row.product_name : '--'}}</template> -->
				</el-table-column>
				<el-table-column prop="linkman" label="帮扶对象联系人" align="center" empty-text="-">
					<!-- <template slot-scope="scope">{{scope.row.linkman ? scope.row.linkman : '--'}}</template> -->
				</el-table-column>
			</el-table>
			<div v-if="$store.state.showIframe.iframeAssets" class="close-btn"
				@click="$store.dispatch(`showIframe/setItemFlase`, `iframeAssets`)">
				<img src="../../assets/img/header/closed_02.png" />
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "iframeTable"
	}
</script>

<style lang="scss" scoped>
	.iframeBox {
		.close-btn {
			top: 15px;
			z-index: 9999999;
			position: absolute;
			right: 15px;
			width: 75px;
			height: 75px;
			cursor: pointer;
			img {
				width: 100%;
				height: 100%;
				box-sizing: border-box;
				display: block;
			}
		}
	}
	/deep/ .el-table {
		thead {
			font-size: 17px !important;
			letter-spacing: 1px;
			background-image: url(../../assets/img/pop_01.png);
			background-repeat: no-repeat;
			background-size: 100% 100%;
			.el-table__cell {
				border-bottom: none !important;
			}
		}
		tr {
		    background-color: transparent;
		}
		th.el-table__cell {
		    overflow: hidden;
		    -webkit-user-select: none;
		    -moz-user-select: none;
		    -ms-user-select: none;
		    user-select: none;
		    background-color: transparent !important;
		}
		td.el-table__cell, .el-table th.el-table__cell.is-leaf {
			//border-bottom: 3px solid rgba(55, 207, 255,.4) !important;
			border-bottom: none !important;
		}
	}
	/deep/ .el-table, .el-table__expanded-cell {
		background-color: transparent !important;
	}
	/deep/ .el-table--border::after, .el-table--group::after, .el-table::before {
		background-color: transparent !important;
	}
	/deep/ .el-table__row:hover {
		background: linear-gradient(270deg, rgba(0, 230, 224, 0) 0%, rgba(55, 207, 255, 0.4) 98%) !important;
		.el-table__cell {
			background-color: transparent !important;
		}
	}
	/deep/ .el-table__body-wrapper {
		// background-image: linear-gradient(0deg, rgba(0,24,78,0.2) 0%, rgba(0,24,78,0.6) 50%);
		background-image: linear-gradient(0deg, rgba(17,55,87, 0.9) 20%, rgba(18, 42, 90, 0.9) 80%);
		border-radius: 10px;
	}
	/deep/ .el-table__body {
		//backdrop-filter: blur(5px);
		//margin-top: 20px;
		//background-image: linear-gradient(0deg, rgba(0,24,78,0.03) 0%, rgba(0,24,78,0.4) 50%);
	}
	/deep/ .el-table__row--striped {
		// background-color: #164883 !important;
		//background-color: #175EB9 !important;
		background: linear-gradient(270deg, rgba(0, 230, 224, 0) 0%, rgba(55,207,255, 0.4) 98%) !important;
		.el-table__cell {
			background: transparent !important;
		}
		
	}
</style>
