import { render, staticRenderFns } from "./iframeTable.vue?vue&type=template&id=05da45e9&scoped=true&"
import script from "./iframeTable.vue?vue&type=script&lang=js&"
export * from "./iframeTable.vue?vue&type=script&lang=js&"
import style0 from "./iframeTable.vue?vue&type=style&index=0&id=05da45e9&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05da45e9",
  null
  
)

export default component.exports