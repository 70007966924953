import request from '../request.js'


//农作物统计信息
export function statisticsMessage(data) {
	return request({
		url: 'api_crop_statistics_message',
		method: 'post',
		data
	})
}



//天地图的接口
export function homepageMap(data) {
	return request({
		url: 'api_map_homepage',
		method: 'post',
		data
	})
}


//农场地图的接口
export function getMapDetail(data) {
	return request({
		url: 'api_shop_vr_map',
		method: 'post',
		data
	})
}

export function getAgricultureStatistics(data) {
	return request({
		url: '/getAgricultureStatistics',
		method: 'post',
		data
	})
}


export function getCulturalTourismStatistics(data) {
	return request({
		url: '/getCulturalTourismStatistics',
		method: 'post',
		data
	})
}


export function getPartyStatistics(data) {
	return request({
		url: '/getPartyStatistics',
		method: 'post',
		data
	})
}


export function getTalentsStatistics(data) {
	return request({
		url: '/getTalentsStatistics',
		method: 'post',
		data
	})
}



// 农业坐标点
export function getAgricultureCoordinate(data) {
	return request({
		url: '/getAgricultureCoordinate',
		method: 'post',
		data
	})
}
// 文化底蕴坐标点及列表接口
export function getCulturalCoordinate(data) {
	return request({
		url: '/getCulturalCoordinate',
		method: 'post',
		data
	})
}
// 旅游景点坐标点及列表接口
export function getTourismCoordinate(data) {
	return request({
		url: '/getTourismCoordinate',
		method: 'post',
		data
	})

}
// 招商坐标点及列表接口
export function getAttractInvestmentCoordinate(data) {
	return request({
		url: '/getAttractInvestmentCoordinate',
		method: 'post',
		data
	})
}


// 农村列表及数据统计
export function getVillageStatistics(data) {
	return request({
		url: '/getVillageStatistics',
		method: 'post',
		data
	})
}
// 党建坐标点及列表接口
export function getPartyCoordinate(data) {
	return request({
		url: '/getPartyCoordinate',
		method: 'post',
		data
	})
}
// 党建人员帮扶记录列表接口
export function getTaskList(data) {
	return request({
		url: '/getTaskList',
		method: 'post',
		data
	})
}
// 党建人员好人好事列表接口
export function getPolict(data) {
	return request({
		url: '/getPolict',
		method: 'post',
		data
	})
}
// 人才坐标点及列表接口
export function getTalentsCoordinate(data) {
	return request({
		url: '/getTalentsCoordinate',
		method: 'post',
		data
	})
}

// 江山人才政策列表接口
export function getTalentPolicyList(data) {
	return request({
		url: '/getTalentPolicyList',
		method: 'post',
		data
	})
}

// suoyouleixing
export function getScreenCoordinate(data) {
	return request({
		url: '/getScreenCoordinate',
		method: 'post',
		data
	})
}


export function getAttractInvestmentStatistics(data) {
	return request({
		url: '/getAttractInvestmentStatistics',
		method: 'post',
		data
	})
}


export function getPlanningProductStatistics(data) {
	return request({
		url: '/getPlanningProductStatistics',
		method: 'post',
		data
	})
}


export function getVillageAttractInvestmentCoordinate(data) {
	return request({
		url: '/getVillageAttractInvestmentCoordinate',
		method: 'post',
		data
	})
}

// 城镇获取
export function getTownList(data) {
	return request({
		url: '/getTownList',
		method: 'post',
		data
	})
}

// 村获取
export function getVillageList(data) {
	return request({
		url: '/getVillageList',
		method: 'post',
		data
	})
}

// 获取供销点位
export function getFarmCoordinate(data) {
	return request({
		url: '/getFarmCoordinate',
		method: 'post',
		data
	})
}
