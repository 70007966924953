<template>
	<div>
		<div v-if="$store.state.showIframe.iframeImg" class="iframeBox">
			<img :src="$store.state.showIframe.ImgSrc" alt="" style="width: 100%; height: 100%;object-fit: cover;" />
			<div v-if="$store.state.showIframe.iframeImg"
				@click="$store.dispatch(`showIframe/setItemFlase`, `iframeImg`)" class="close-btn2">
				<img src="../../assets/img/header/closed_02.png" />
			</div>
		</div>
		<!-- <el-button class="iconInIframe" icon="el-icon-circle-close " v-if="$store.state.showIframe.iframeImg"
			@click="$store.dispatch(`showIframe/setItemFlase`, `iframeImg`)"></el-button> -->
	</div>
</template>
<script>
	export default {
		name: "iframeImg",
		props: {},
		data() {
			return {
				src: "",
			};
		},
		methods: {
			resetCity() {
				this.$emit("resetCity");
			},
		},
		mounted() {},
	};
</script>

<style scoped lang="scss">
	
</style>
