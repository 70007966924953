<template>
	<div class="region">
		<div class="title">
			<img src="@/assets/img/header/indicate.png"/>
			<span>所在地区</span>
		</div>
		<div class="content_box">
			<div class="select_box" @click="handleOpen('city')">
				<span>{{region.item.city_name}}</span>
				<img v-if="region.isShow && currentType == 'city'" class="icon_up" src="@/assets/img/header/down_01.png"/>
				<img v-else src="@/assets/img/header/down_01.png"/>
			</div>
			<div class="select_box" @click="handleOpen('town')">
				<span>{{region.item.town_name ? region.item.town_name : '-'}}</span>
				<img v-if="region.isShow && currentType == 'town'" class="icon_up" src="@/assets/img/header/down_01.png"/>
				<img v-else src="@/assets/img/header/down_01.png"/>
			</div>
			<div class="select_box" @click="handleOpen('village')">
				<span>{{region.item.village_name ? region.item.village_name : '-'}}</span>
				<img v-if="region.isShow && currentType == 'village'" class="icon_up" src="@/assets/img/header/down_01.png"/>
				<img v-else src="@/assets/img/header/down_01.png"/>
			</div>
		</div>
		<!-- 地区选择的弹窗 -->
		<div class="select_module" v-if="region.isShow">
			<div class="top">
				<div class="top_lt">
					<img src="@/assets/img/header/indicate.png"/>
					<span>位置区域</span>
				</div>
				<img class="top_rt" src="@/assets/img/header/closed_01.png" @click="handleClosed"/>
			</div>
			<div class="bottom" v-if="regions.length > 0">
				<div class="box_item" 
					:class="[item[getItemName(item)] == region.item[getItemName(item)] ? 'active' : '']"
					v-for="(item,index) in regions" :key="index"
					@click="handleSelectItem(item)"
				>{{item[getItemName(item)]}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { getTownList,getVillageList } from '@/utils/api/index.js'
import { getCityList } from '@/utils/api/region.js'
export default {
	data() {
		return {
			regions: [],
			currentType: ''
		}
	},
	computed: {
		...mapState(['region']),  // 这里的user 指代的是模块名 /  如果是没有单独分开模块，则直接放state里的变量名
		getItemName() {
			return (item) => {
				if (this.currentType === 'city') {
					return 'city_name';
				} else if (this.currentType === 'town') {
					return 'town_name';
				} else if (this.currentType === 'village') {
					return 'village_name';
				} else {
					return '';
				}
			}
		}
	},
	methods: {
		async getCityList() {
			let res = await getCityList();
			console.log(res,333)
			this.regions = res.data.areaList.map(item => ({
				...item,
				city_name: item.name
			}));
			console.log(this.regions,55)
		},
		async getTownList() {
			let res = await getTownList({
				distinct: this.region.item.city_id
			});
			this.regions = res.data;
		},
		async getVillageList() {
			let res = await getVillageList({
				town: this.region.item.town_code
			});
			if(res.data.length > 0) {
				res.data.unshift({
					town_code: "21",
					village_id: '',
					village_name: "全部"
				})
			}
			this.regions = res.data;
		},
		handleOpen(type) {
			this.$store.dispatch(`region/setOpenState`, !this.region.isShow);
			this.currentType = type;
			this.handleSelectRegion(type);
		},
		handleClosed() {
			this.$store.dispatch(`region/setOpenState`, false);
		},
		handleSelectItem(item) {
			if (this.currentType === 'city') {
				this.$store.dispatch(`region/setCity`, item);
			} else if (this.currentType === 'town') {
				this.$store.dispatch(`region/setTown`, item);
			} else if (this.currentType === 'village') {
				this.$store.dispatch(`region/setVillage`, item);
			}
			this.handleClosed();
		},
		handleSelectRegion(type) {
			if (type === 'town') {
				this.getTownList();
				return this.regions; // 渲染乡镇数据列
			} else if (type === 'village') {
				this.getVillageList()
				return this.regions; // 渲染村数据列
			} else if (type === 'city') {
				this.getCityList()
				return this.regions; // 渲染区县数据列
			} else {
				return [];
			}
		},
	}
}
</script>

<style lang="scss" scoped>
	.region {
		width: 100%;
		height: 100%;
		.title {
			width: 100%;
			height: 42px;
			line-height: 42px;
			display: flex;
			align-items: center;
			padding: 9px 0;
			font-size: 18px;
			font-weight: 600;
			color: #ffffff;
			background-repeat: no-repeat;
			background-size: 100% 100%;
			font-family: PingFang SC-Bold, PingFang SC;
			background-image: url(../../assets/img/header/title_03.png);
			letter-spacing: 1px;
			img {
				width: 24px;
				height: 24px;
				margin-right: 16px;
				margin-top: 2px;
			}
		}
		.content_box {
			background-image: linear-gradient(0deg, rgba(20, 26, 38, 0.8) 0%, rgba(32, 45, 70, 0.29) 100%);
			box-shadow: inset 0 0 0.15625rem 0 rgba(45, 93, 117, 0.2);
			height: 0.260417rem;
			width: 100%;
			margin-top: 0.0625rem;
			display: flex;
			align-items: center;
			gap: 0 8px;
			span {
				color: #7589B1;
				font-size: 16px;
				margin-right: 16px;
				margin-left: 23px;
			}
			.select_box {
				width: 110px;
				height: 26px;
				line-height: 26px;
				background: #142D60;
				border: 1px solid rgba(59,102,188,1);
				border-radius: 2px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				cursor: pointer;
				span {
					margin-left: 12px;
					color: #fff;
					font-size: 14px;
				}
				img {
					width: 24px;
					height: 24px;
					transition: all .5s;
				}
				.icon_up {
					transform: rotate(180deg);
					-webkit-transform: rotate(180deg);
					transition: transform .5s;
				}
			}
		}
		.select_module {
			position: absolute;
			top: 10px;
			left: 453px;
			z-index: 3333;
			color: #fff;
			width: 15%;
			padding: 14px 16px;
			background-image: linear-gradient(0deg, rgba(20,26,38,0.03) 0%, rgba(28,72,157,0.33) 91%);
			.top {
				font-size: 16px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding-bottom: 12px;
				border-bottom: 3px solid #2F96FD;
				img {
					width: 24px;
					height: 24px;
				}
				.top_lt {
					display: flex;
					align-items: center;
					span {
						margin-left: 8px;
					}
				}
				.top_rt {
					cursor: pointer;
				}
			}
			.bottom {
				font-size: 14px;
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				text-align: center;
				grid-gap: 12px;
				margin-top: 12px;
				.box_item {
					background: rgba(255,255,255,0.10);
					border-radius: 2px;
					padding: 6px;
					color: #fff;
					cursor: pointer;
				}
				.active {
					color: #38FFFA;
					background: rgba(56,119,242,0.30);
				}
			}
		}
	}
</style>